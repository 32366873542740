<template>
  <div
    class="flex flex-col border-r pr-4 border-solid border-gray-200 m-container"
  >
    <div class="flex justify-between align-baseline" id="header">
      <p class="text-base-content-300 mb-0 text-sm grow">{{ title }}</p>
      <!-- <EditPencil @click="handleEditClick" class="cursor-pointer"/> -->
    </div>
    <p
      v-if="this.$route.meta.showLightRay"
      class="font-semibold mb-0 text-sm capitalize"
      :class="!value && 'text-gray-400'"
    >
      {{ lightRayCaseId || getCaseFromSessionStorage }}
    </p>
    <p
      v-else
      class="font-semibold mb-0 text-sm capitalize"
      :class="!value && 'text-gray-400'"
    >
      {{ value }}
    </p>
  </div>
</template>

<script>
// import EditPencil from "@shared/assets/edit-pencil.svg"

export default {
  components: {
    // EditPencil
  },
  computed: {
    lightRayCaseId() {
      return this.$store.getters.getLightRayCaseId;
    },
    getCaseFromSessionStorage() {
      return sessionStorage.getItem("neo_case_id");
    },
  },
  methods: {
    handleEditClick() {
      console.log("CLicked");
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    showLightRay: {
      type: Boolean,
      default: () => false,
    }
  },
};
</script>

<style>
.m-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  min-width: 150px;
}
#header {
  min-width: inherit;
}
</style>
