import {Logo1} from "@/plugins/common";
import creditsIcon from "@/assets/icons/svg/credit-pack.svg";
// import creditsIcon from "@/assets/icons/svg/credit-pack.svg"
// import Vue from "vue";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
// Svg Imports
import purchaseOrdersSvgIcon from '@/assets/icons/svg/navigation/admin-section/purchase-orders-main.svg'
// import { checkPermission } from "@shared/utils/functions"

export default {
  name: "app-sidebar",
  components: {},
  props: [],
  data() {
    return {
      navigation: [
        {
          link: "/create-case",
          name: "Create Case",
          icon: "user-shield",
        },
        {
          link: "/",
          name: "dashboard",
          icon: "home",
        },
        // {
        //   link: "/packages",
        //   name: "Packages",
        //   icon: "database",
        // },
        {
          link: "/admin",
          name: "admin",
          icon: "cog",
          children: [
            // {
            //   link: '/check-admin',
            //   name: 'check admin',
            //   icon: 'cog'
            // },
            // {
            //   link: "/field-table",
            //   name: "Fields",
            //   icon: "table",
            // },
            // {
            //   link: "/check-admin",
            //   name: "Checks",
            //   icon: "check",
            // },
            
            // {
            //   link: "/clients-list",
            //   name: "Clients",
            //   icon: "user-secret",
            // },
            {
              link: "/users",
              name: "users",
              icon: "user",
            },
            {
              link: "/groups",
              name: "groups",
              icon: "users-cog",
            },
            // {
            //   link: "/profile-view",
            //   name: "profileView",
            //   icon: "user",
            // },
            {
              link: '/credit-packs',
              name: 'Credits Pack',
              svg: creditsIcon
            },
            {
              link: "/purchase-orders",
              name: "Purchase Orders",
              svg: purchaseOrdersSvgIcon,
            },
            {
              link: "/license",
              name: "License",
              icon: 'user'

            }
            // {
            //   link: "/profile-view",
            //   name: "profileView",
            //   icon: "user",
            // },
            // {
            //   link: "/clients",
            //   name: "clients",
            //   icon: "user",
            // },
            // {
            //   link: '/pages',
            //   name: 'sections admin',
            //   icon: 'car'
            // },
          ],
        },
        // {
        //   link: "/tools",
        //   name: "Tools",
        //   icon: "tools",
        //   iconPrefix: 'fa',
        //   customLink: true,
        // },
      ],
      Logo1,
    };
  },
  computed: {
    ...mapGetters(['getThemeLogo','getTenantLogo', 'getLoginTenantDetails', 'getTenantLogoLoading'
    // ,'getIsPermission'
  ]),
    isNavCollapsed() {
      return this.$store.getters.getNavCollapsed ?? false;
    },
    // isPermissionUpdate() {
    //   debugger; // eslint-disable-line no-debugger
    //   return this.$store.getters.getIsPermission
    // }
  },
  // watch: {
  //   isPermissionUpdate() {
  //     this.navigationList();
  //   }
  // },
  mounted() {
    console.log("this.$route :>> ", this.$route);
    if(this.$route.path.includes('#fileManager')) {
      VueCookies.set("next-url",this.$route.fullPath)
    }
    if(this.$route?.query?.clientid && this.$store.getters.getClientId && this.$route?.query?.clientid !== this.$store.getters.getClientId)
      this.$store.dispatch("logoutUser");
  },
  methods: {
    // checkPermission,
    isActive(link) {
      console.log("this.$route.path", this.$route.path)
      return this.$route.path === link;
    },
    navCollapseToggle(){
      const navCollapsed = this.$store.getters.getNavCollapsed || false
      this.$store.dispatch('setNavCollapsed', !navCollapsed)
    },
    handleNavClick(nav) {
      let dropdownElement = document.getElementById('dashboard_container_embeddedbi_ConfigMenu');
      if (dropdownElement) dropdownElement.style.display = 'none'
      if (!this.isActive(nav.link)) {
        if (nav.children && nav.children.length > 0) {
          let expanded = nav.expanded || false;
          this.navigation = this.navigation.map((nve) =>
            nve.link === nav.link ? { ...nav, expanded: !expanded } : nve
          );
        } else
          this.$router
            .push({ path: nav.link })
            .catch((e) => console.log("e >> handleNavClick ", e));
      }
    },
    tenantLogoView() {
      if(this.getTenantLogoLoading) {
        return
      }
      if (this.getThemeLogo)
        return this.getThemeLogo;
      else if(this.getTenantLogo) {
        return this.getTenantLogo;
      } else {
      return this.Logo1;
    }
    }
  },
};
